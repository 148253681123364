// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__loadingRow--TpLKA {
  align-items: center;
  display: flex;
  height: 156px;
  justify-content: center;
  width: 100%;
}
.style__tableRow--wI9_a:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/style.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,WAAA;AAAJ;AAII;EACE,0CAAA;AAFN","sourcesContent":[":local {\n  .loadingRow {\n    align-items: center;\n    display: flex;\n    height: 156px;\n    justify-content: center;\n    width: 100%;\n  }\n\n  .tableRow {\n    &:hover {\n      background: rgba(0, 0, 0, 0.04) !important;\n    }\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingRow": `style__loadingRow--TpLKA`,
	"tableRow": `style__tableRow--wI9_a`
};
export default ___CSS_LOADER_EXPORT___;
