// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__add--cTW6N {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.25px;
}
.style__addUserButton--VckvB {
  background: #eeeeee;
  height: 146px;
  width: 292px;
}
.style__backButton--uqsre {
  left: 24px;
  position: absolute;
}
.style__content--g8MJG {
  align-content: space-between;
  background: #fafafa;
  display: flex;
  flex-grow: 1;
  padding: 48px 24px;
  width: 100%;
}
.style__itemBox--Kjq8T {
  margin-bottom: 24px;
  margin-left: 24px;
}
.style__itemListColumn--ZobRC {
  align-content: flex-start;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}
.style__navigation--JsG6f {
  display: flex;
  height: 104px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 100%;
}
.style__settings--vcArO {
  display: flex;
  flex-direction: column;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Settings/style.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,gBAAA;EACA,sBAAA;AAAJ;AAJA;EAQI,mBAAA;EACA,aAAA;EACA,YAAA;AADJ;AATA;EAcI,UAAA;EACA,kBAAA;AAFJ;AAbA;EAmBI,4BAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;AAHJ;AArBA;EA4BI,mBAAA;EACA,iBAAA;AAJJ;AAzBA;EAiCI,yBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AALJ;AA/BA;EAwCI,aAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;AANJ;AAvCA;EAiDI,aAAA;EACA,sBAAA;EACA,YAAA;AAPJ","sourcesContent":[":local {\n  .add {\n    font-size: 14px;\n    font-weight: 400;\n    letter-spacing: 1.25px;\n  }\n\n  .addUserButton {\n    background: #eeeeee;\n    height: 146px;\n    width: 292px;\n  }\n\n  .backButton {\n    left: 24px;\n    position: absolute;\n  }\n\n  .content {\n    align-content: space-between;\n    background: #fafafa;\n    display: flex;\n    flex-grow: 1;\n    padding: 48px 24px;\n    width: 100%;\n  }\n\n  .itemBox {\n    margin-bottom: 24px;\n    margin-left: 24px;\n  }\n\n  .itemListColumn {\n    align-content: flex-start;\n    display: flex;\n    flex-grow: 1;\n    flex-wrap: wrap;\n  }\n\n  .navigation {\n    display: flex;\n    height: 104px;\n    justify-content: center;\n    padding: 24px;\n    position: relative;\n    width: 100%;\n  }\n\n  .settings {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add": `style__add--cTW6N`,
	"addUserButton": `style__addUserButton--VckvB`,
	"backButton": `style__backButton--uqsre`,
	"content": `style__content--g8MJG`,
	"itemBox": `style__itemBox--Kjq8T`,
	"itemListColumn": `style__itemListColumn--ZobRC`,
	"navigation": `style__navigation--JsG6f`,
	"settings": `style__settings--vcArO`
};
export default ___CSS_LOADER_EXPORT___;
