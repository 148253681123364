// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__card--UbsfB {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 16px;
  position: relative;
}
.style__cardText--Upr4N {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1.5px;
}
.style__content--a3Pxm {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.style__state--ebncu {
  background: #00ee77;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  height: 24px;
  justify-content: center;
  text-align: center;
  width: 74px;
}
.style__userCard--SolVM {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);
  height: 146px;
  position: relative;
  width: 292px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Settings/components/UserCard/style.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,kBAAA;AAAJ;AAPA;EAWI,eAAA;EACA,gBAAA;EACA,qBAAA;AADJ;AAZA;EAiBI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AAFJ;AApBA;EA0BI,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;AAHJ;AA9BA;EAqCI,mBAAA;EACA,kBAAA;EACA,0CAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;AAJJ","sourcesContent":[":local {\n  .card {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    justify-content: space-between;\n    padding: 16px;\n    position: relative;\n  }\n\n  .cardText {\n    font-size: 10px;\n    font-weight: 400;\n    letter-spacing: 1.5px;\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    width: 100%;\n  }\n\n  .state {\n    background: #00ee77;\n    border-radius: 24px;\n    display: flex;\n    flex-direction: column;\n    height: 24px;\n    justify-content: center;\n    text-align: center;\n    width: 74px;\n  }\n\n  .userCard {\n    background: #ffffff;\n    border-radius: 4px;\n    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);\n    height: 146px;\n    position: relative;\n    width: 292px;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `style__card--UbsfB`,
	"cardText": `style__cardText--Upr4N`,
	"content": `style__content--a3Pxm`,
	"state": `style__state--ebncu`,
	"userCard": `style__userCard--SolVM`
};
export default ___CSS_LOADER_EXPORT___;
