// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__label--vXS6p {
  display: block;
  font-family: monospace;
}
`, "",{"version":3,"sources":["webpack://./src/components/TimeLabel/style.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;EACA,sBAAA;AAAJ","sourcesContent":[":local {\n  .label {\n    display: block;\n    font-family: monospace;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `style__label--vXS6p`
};
export default ___CSS_LOADER_EXPORT___;
