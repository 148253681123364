// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__aspectRatio--Ocark {
  border-radius: 4px;
  bottom: 0;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  top: 0;
  width: 100%;
}
.style__overlay--T7KAN {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.38);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
@media (min-width: 1364px) {
  .style__overlay--T7KAN {
    height: 26rem;
  }
}
.style__overlayDisabled--gpncF {
  background-color: transparent;
}
.style__player--XFQ6s {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/VideoPlayer/style.less"],"names":[],"mappings":"AAEA;EAEI,kBAAA;EACA,SAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;AAFJ;AANA;EAYI,mBAAA;EACA,qCAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,UAAA;AAHJ;AAKI;EAAA;IACE,aAAA;EAFJ;AACF;AAtBA;EA4BI,6BAAA;AAHJ;AAzBA;EAgCI,YAAA;EACA,OAAA;EACA,kBAAA;EACA,WAAA;AAJJ","sourcesContent":["@import '../../style/responsive.less';\n\n:local {\n  .aspectRatio {\n    border-radius: 4px;\n    bottom: 0;\n    height: 0;\n    padding-top: 56.25%;\n    position: relative;\n    top: 0;\n    width: 100%;\n  }\n\n  .overlay {\n    align-items: center;\n    background-color: rgba(0, 0, 0, 0.38);\n    display: flex;\n    height: 100%;\n    justify-content: center;\n    position: absolute;\n    top: 0;\n    width: 100%;\n    z-index: 3;\n\n    @media (min-width: @breakpoint-xl) {\n      height: 26rem;\n    }\n  }\n\n  .overlayDisabled {\n    background-color: transparent;\n  }\n\n  .player {\n    height: 100%;\n    left: 0;\n    position: absolute;\n    width: 100%;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aspectRatio": `style__aspectRatio--Ocark`,
	"overlay": `style__overlay--T7KAN`,
	"overlayDisabled": `style__overlayDisabled--gpncF`,
	"player": `style__player--XFQ6s`
};
export default ___CSS_LOADER_EXPORT___;
