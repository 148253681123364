// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__centerIcon--aTsP0 {
  bottom: 19px;
  left: 19px;
  position: absolute;
}
.style__icon--o_lb8 {
  bottom: 16px;
  fill: #ffffff;
  left: 16px;
  position: absolute;
}
.style__mapNavigation--vDHi9 {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
}
.style__navButton--U9sHS {
  align-items: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #121212;
  border-radius: 28px;
  border: none;
  box-shadow: 0 1px 18px rgba(0, 0, 0, 0.12);
  display: inline-block;
  height: 56px;
  position: relative;
  width: 56px;
}
.style__navButton--U9sHS :focus {
  outline: 0;
}
.style__zoomGroup--XCBdY {
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/MapNavigation/style.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,UAAA;EACA,kBAAA;AAAJ;AAJA;EAQI,YAAA;EACA,aAAA;EACA,UAAA;EACA,kBAAA;AADJ;AAVA;EAeI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;AAFJ;AAhBA;EAsBI,mBAAA;EACA,gGAAA;EAMA,mBAAA;EACA,YAAA;EACA,0CAAA;EACA,qBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;AARJ;AA3BA;EAqCM,UAAA;AAPN;AA9BA;EA0CI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;AATJ","sourcesContent":[":local {\n  .centerIcon {\n    bottom: 19px;\n    left: 19px;\n    position: absolute;\n  }\n\n  .icon {\n    bottom: 16px;\n    fill: #ffffff;\n    left: 16px;\n    position: absolute;\n  }\n\n  .mapNavigation {\n    display: flex;\n    flex-direction: column;\n    height: 200px;\n    justify-content: space-between;\n  }\n\n  .navButton {\n    align-items: center;\n    background: linear-gradient(\n        0deg,\n        rgba(255, 255, 255, 0.11),\n        rgba(255, 255, 255, 0.11)\n      ),\n      #121212;\n    border-radius: 28px;\n    border: none;\n    box-shadow: 0 1px 18px rgba(0, 0, 0, 0.12);\n    display: inline-block;\n    height: 56px;\n    position: relative;\n    width: 56px;\n    :focus {\n      outline: 0;\n    }\n  }\n\n  .zoomGroup {\n    display: flex;\n    flex-direction: column;\n    height: 120px;\n    justify-content: space-between;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centerIcon": `style__centerIcon--aTsP0`,
	"icon": `style__icon--o_lb8`,
	"mapNavigation": `style__mapNavigation--vDHi9`,
	"navButton": `style__navButton--U9sHS`,
	"zoomGroup": `style__zoomGroup--XCBdY`
};
export default ___CSS_LOADER_EXPORT___;
