// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__deleteEventDialog--kffy3 {
  background: #282828;
}
.style__deleteEventDialog--kffy3 button {
  color: #00ee77;
  font-size: 14px;
  letter-spacing: 1.25px;
}
.style__deleteEventDialog--kffy3 p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  letter-spacing: 0.5px;
}
.style__incidentColumn--C652m {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #121212;
  display: flex;
  flex-grow: 1;
}
.style__incidentNav--fB0_D {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.style__menu--zPPir {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #121212;
  border-radius: 4px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.12);
  font-size: 14px;
  height: 84px;
  margin-left: 25px;
  margin-top: 88px;
  width: 193px;
}
.style__menuOverlay--rnEYf {
  background: rgba(0, 0, 0, 0.38);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}
`, "",{"version":3,"sources":["webpack://./src/components/IncidentColumn/style.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;AAAJ;AAEI;EACE,cAAA;EACA,eAAA;EACA,sBAAA;AAAN;AAGI;EACE,+BAAA;EACA,eAAA;EACA,qBAAA;AADN;AAZA;EAkBI,gGAAA;EAMA,aAAA;EACA,YAAA;AARJ;AAjBA;EA6BI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;EACA,kBAAA;EACA,WAAA;AATJ;AAzBA;EAsCI,gGAAA;EAMA,kBAAA;EACA,0CAAA;EACA,eAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;AAfJ;AAnCA;EAsDI,+BAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;AAhBJ","sourcesContent":[":local {\n  .deleteEventDialog {\n    background: #282828;\n\n    & button {\n      color: #00ee77;\n      font-size: 14px;\n      letter-spacing: 1.25px;\n    }\n\n    & p {\n      color: rgba(255, 255, 255, 0.6);\n      font-size: 16px;\n      letter-spacing: 0.5px;\n    }\n  }\n\n  .incidentColumn {\n    background: linear-gradient(\n        0deg,\n        rgba(255, 255, 255, 0.07),\n        rgba(255, 255, 255, 0.07)\n      ),\n      #121212;\n    display: flex;\n    flex-grow: 1;\n  }\n\n  .incidentNav {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    justify-content: space-between;\n    position: relative;\n    width: 100%;\n  }\n\n  .menu {\n    background: linear-gradient(\n        0deg,\n        rgba(255, 255, 255, 0.12),\n        rgba(255, 255, 255, 0.12)\n      ),\n      #121212;\n    border-radius: 4px;\n    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.12);\n    font-size: 14px;\n    height: 84px;\n    margin-left: 25px;\n    margin-top: 88px;\n    width: 193px;\n  }\n\n  .menuOverlay {\n    background: rgba(0, 0, 0, 0.38);\n    height: 100%;\n    position: absolute;\n    width: 100%;\n    z-index: 2;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteEventDialog": `style__deleteEventDialog--kffy3`,
	"incidentColumn": `style__incidentColumn--C652m`,
	"incidentNav": `style__incidentNav--fB0_D`,
	"menu": `style__menu--zPPir`,
	"menuOverlay": `style__menuOverlay--rnEYf`
};
export default ___CSS_LOADER_EXPORT___;
