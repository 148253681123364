// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style__incidentHeader--cu7fO {
  background-color: rgba(0, 0, 0, 0.38);
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Mono, monospace';
  font-size: 14px;
  height: 100px;
  padding: 24px;
  position: sticky;
  z-index: 1;
}
.style__eventContainer--B5pfA {
  display: flex;
  font-size: 24px;
  justify-content: space-between;
  padding-top: 12px;
}
.style__flex--IGxbL {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/IncidentHeader/style.less"],"names":[],"mappings":"AAAA;EAEI,qCAAA;EACA,+BAAA;EACA,aAAA;EACA,sBAAA;EACA,uCAAA;EACA,eAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EACA,UAAA;AAAJ;AAXA;EAeI,aAAA;EACA,eAAA;EACA,8BAAA;EACA,iBAAA;AADJ;AAjBA;EAsBI,aAAA;AAFJ","sourcesContent":[":local {\n  .incidentHeader {\n    background-color: rgba(0, 0, 0, 0.38);\n    color: rgba(255, 255, 255, 0.6);\n    display: flex;\n    flex-direction: column;\n    font-family: 'IBM Plex Mono, monospace';\n    font-size: 14px;\n    height: 100px;\n    padding: 24px;\n    position: sticky;\n    z-index: 1;\n  }\n\n  .eventContainer {\n    display: flex;\n    font-size: 24px;\n    justify-content: space-between;\n    padding-top: 12px;\n  }\n\n  .flex {\n    display: flex;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"incidentHeader": `style__incidentHeader--cu7fO`,
	"eventContainer": `style__eventContainer--B5pfA`,
	"flex": `style__flex--IGxbL`
};
export default ___CSS_LOADER_EXPORT___;
